import { template as template_46a9d708462246ec81cb2231d107365b } from "@ember/template-compiler";
const WelcomeHeader = template_46a9d708462246ec81cb2231d107365b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
